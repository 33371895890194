;(function ($) { $.fn.datepicker.language['bg'] = {
    days: ['Неделя', 'Понеделник', 'Вторник', 'Сряда', 'Четвъртък', 'Петък', 'Събота'],
    daysShort: ['Нед', 'Пон', 'Вто', 'Сря', 'Чет', 'Пет', 'Съб'],
    daysMin: ['Н', 'По', 'Вт', 'Ср', 'Ч', 'Пе', 'С'],
    months: ['Януари','Февруари','Март','Април','Май','Юни', 'Юли','Август','Септември','Октомври','Ноември','Декември'],
    monthsShort: ['Ян','Февр','Март','Април','Май','Юни', 'Юли','Авг','Септ','Окт','Ноем','Дек'],
    today: 'Днес',
    clear: 'Изчисти',
    dateFormat: 'yyyy-mm-dd',
    timeFormat: 'hh:ii aa',
    firstDay: 1
}; })(jQuery);